import COLORS from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    background: COLORS.kgGreen,
    color: COLORS.offBlack
  },
  content: {
    flexDirection: 'column',
    display: 'flex',
    maxWidth: 1200,
    margin: '0 auto',
    [QUERIES.desktop]: {
      minHeight: '80vh'
    }
  },
  h1: {
    color: 'white',
    marginBottom: 0,
    fontSize: 48,
    [QUERIES.mobile]: {
      textAlign: 'center'
    }
  },
  subTitle: {
    paddingBottom: 8,
    marginTop: 8,
    color: COLORS.kgDarkGreen,
    [QUERIES.mobile]: {
      display: 'none'
    }
  },
  contentRow: {
    flexWrap: 'wrap'
  },
  vanName: {
    textAlign: 'left',
    paddingLeft: 32,
    paddingBottom: 8,
    opacity: 0.35
  },
  vanImg: {
    width: '100%',
    maxWidth: 140
  },
  toolsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  tools: {
    padding: 16,
    [QUERIES.mobile]: {
      paddingBottom: 48
    },
    [QUERIES.desktop]: {
      padding: 32,
      paddingLeft: 16
    }
  },
  appLink: {
    color: COLORS.paperText,
    textDecoration: 'none'
  },
  phoneLink: {
    textDecoration: 'none',
    color: COLORS.kgDarkGreen
  },
  price: {
    fontSize: 24,
    fontWeight: '700'
  },
  miles: {
    paddingTop: 8,
  },
  selectHelpLabel: {
    fontSize: 16,
    color: COLORS.white,
    fontWeight: '700',
    marginBottom: 6
  },
  selectHelpButton: {
    fontSize: 14,
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: 16,
    flex: 1,
    background: COLORS.kgDarkGreen,
    color: COLORS.white
  },
  selectHelpButton1: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  selectHelpButton2: {
    borderRadius: 0
  },
  selectHelpButton3: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  selected: {
    background: COLORS.white,
    color: COLORS.offBlack
  },

  // Table stuff:
  table: {
    background: COLORS.white,
    borderRadius: 8,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    boxShadow: '0 16px 40px rgba(0,0,0,0.12)',
    [QUERIES.mobile]: {
      borderRadius: 0,
      flex: '0 0 100%',
      boxShadow: 'none'
    }
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  cell: {
    textAlign: 'center',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  cellContent: {
    borderBottom: '1px solid whitesmoke',
    padding: 16,
    paddingTop: 32,
    paddingBottom: 32,
    textAlign: 'center'
  },
  cellContentVans: {
    [QUERIES.desktop]: {
      paddingBottom: 48
    }
  },
});

export default styles;
