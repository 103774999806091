import PricingTable from './PricingTable';
import React, { Component } from 'react';
import PRICING_TABLE from '../../../library/pricing';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { navigate } from 'gatsby';

class PricingTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHelp: 1
    }
  }

  changeHelp = (index) => {
    this.setState({
      selectedHelp: index
    })
  };

  trackBookOnlineButtonPress = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Book online clicked - PricingTable',
      label: 'Pricing'
    });
    ReactPixel.track('InitiateCheckout', {
      content_name: 'Clicked Book Online Button',
      content_category: 'Pricing'
    });
    navigate('/book');
  };

  trackDownloadButtonPress = () => {
    ReactGA.event({
      category: 'Download',
      action: 'Download clicked - PricingTable',
      label: 'Pricing'
    });
    ReactPixel.track('InitiateCheckout', {
      content_name: 'Clicked Download Button',
      content_category: 'Pricing'
    });
    navigate('/download');
  };

  render() {
    return (
      <PricingTable
        {...this.props}
        selectedHelp={this.state.selectedHelp}
        changeHelp={this.changeHelp}
        trackDownloadButtonPress={this.trackDownloadButtonPress}
        trackBookOnlineButtonPress={this.trackBookOnlineButtonPress}
      />
    )
  }
}

function mapStateToProps() {
  return {
    pricingTable: PRICING_TABLE
  }
}

export default connect(mapStateToProps)(PricingTableContainer);

