import React from 'react';
import styles from './PricingTable.styles';
import { css } from 'aphrodite';
import { VANS } from '../../../library/vans';
import STYLES from '../../../styles/shared';
import Button from '@material-ui/core/Button';
import PrimaryButton from '../../../components/buttons/PrimaryButton/PrimaryButton';

const TitleAndDescription = () => (
  <div>
    <h1 className={css(styles.h1)}>Affordable</h1>
    <p className={css(styles.subTitle)}>At your door within 1 hour. <b>Booking is available 24/7</b></p>
  </div>
);

const PricingTable = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <div className={css(STYLES.mobileOnly)} style={{padding: 16}}>
        <TitleAndDescription />
      </div>
      <div style={{height: 64}} className={css(STYLES.desktopOnly)} />
      <div className={css(STYLES.fullRow, styles.contentRow)}>
        <div style={{flex: 1}}>
          <div className={css(styles.toolsContainer)}>
            <div style={{flex: 1}}/>
            <div className={css(styles.tools)}>
              <div className={css(STYLES.desktopOnly)}>
                <TitleAndDescription />
              </div>

              <p className={css(styles.selectHelpLabel)}>Select lifting help:</p>
                <div className={css(STYLES.fullRow)}>
                <Button variant={'contained'} size={'large'} color={'default'}
                        className={css(styles.selectHelpButton, styles.selectHelpButton1, props.selectedHelp === 1 && styles.selected)} onClick={() => props.changeHelp(1)}>Driver help</Button>
                <Button variant={'contained'} size={'large'} color={'default'}
                        className={css(styles.selectHelpButton, styles.selectHelpButton2, props.selectedHelp === 2 && styles.selected)} onClick={() => props.changeHelp(2)}>2 Helpers</Button>
                <Button variant={'contained'} size={'large'} color={'default'}
                        className={css(styles.selectHelpButton, styles.selectHelpButton3, props.selectedHelp === 3 && styles.selected)} onClick={() => props.changeHelp(3)}>3 Helpers</Button>
                <div className={css(STYLES.desktopOnly)} style={{width: 48}} />
              </div>

              <div style={{height: 32}} />
              <PrimaryButton color={'yellow'} large={true} style={{marginRight: 16}} onClick={props.trackBookOnlineButtonPress} title={'Book online'} />
            </div>
            <div style={{flex: 1}}/>
          </div>
        </div>
        <div className={css(styles.table)}>
          {Object.values(props.pricingTable).map((service, serviceIndex) => {
            return (
              <div className={css(styles.column)} key={serviceIndex}>
                <div className={css(styles.cell)}>
                  <div className={css(styles.cellContent, styles.cellContentVans)}>
                    <div className={css(styles.vanName)}>{service.name}</div>
                    <img alt={'Cheap ' + VANS[serviceIndex].name + ' van hire in London'} className={css(styles.vanImg)} src={VANS[serviceIndex].img}/>
                  </div>
                </div>

                {service[props.selectedHelp].map((price, i) => {
                  return (
                    <div key={i} className={css(styles.cell)}>
                      <div className={css(styles.cellContent)}>
                        <div className={css(styles.price)}>{price}</div>
                        <div className={css(styles.miles)}>{service.quotas[i]}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>

      </div>
      <div style={{flex: 1}}/>
    </div>
  </div>
);

export default PricingTable;
