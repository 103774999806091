const m1 = { // driver assistance
  's': ['£50', '£74'],// + £12 per 30mins,
  'm': ['£63', '£123'],// + £15 per 30mins,
  'l': ['£113', '£449'],// + £28 per 30mins
};

const m2 = { // driver assistance + 1 extra helper
  's': ['£100', '£150'],// + £25 per 30mins,
  'm': ['£130', '£262'],// + £33 per 30mins,
  'l': ['£130', '£562'],// + £33 per 30mins,
};

const m3 = { // driver assistance + 2 extra helpers
  's': ['£144', '£214'],// + £35 per 30mins,
  'm': ['£180', '£360'],// + £45 per 30mins,
  'l': ['£180', '£720'],// + £45 per 30mins,
};

const PRICING_TABLE = {
  's': {
    name: 'Medium',
    quotas: ['2 hours', '3 hours'],
    // 0: m0['s'], // no help
    1: m1['s'], // driver assistance (default)
    2: m2['s'], // driver assistance + 1 extra helper
    3: m3['s'], // driver assistance + 2 extra helpers
  },
  'm': {
    name: 'Large',
    quotas: ['2 hours', '4 hours'],
    // 0: m0['m'],
    1: m1['m'],
    2: m2['m'],
    3: m3['m']
  },
  'l': {
    name: 'XL',
    quotas: ['2 hours', '8 hours'],
    // 0: m0['l'],
    1: m1['l'],
    2: m2['l'],
    3: m3['l']
  }
};

export default PRICING_TABLE;
