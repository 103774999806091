import React from 'react';
import PricingTableWithChoices from '../containers/sections/PricingTable/PricingTableContainer';
import Layout from '../containers/gatsby/layout';

const PricingPage = (props) => (
  <Layout location={props.location}>
    <PricingTableWithChoices />
  </Layout>
);

export default PricingPage;
